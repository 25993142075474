import React, { Fragment, useState, useEffect } from 'react';

import QualtricsModal from 'components/Shared/QualtricsSurveys/QualtricsModal/QualtricsModal';
import SegmentIO from 'reporting/SegmentIO';
import { useSelector } from 'store/utils';
import { COUNTRY } from 'types/constants';
export interface Props {}

const UnpayableSurvey: React.FC<Props> = () => {
  const store = useSelector((state) => state);
  const {
    insight: { payorSegmentation },
    companyInfo,
    featureFlags,
  } = store;
  const isNonPayEnableInvoiceFlag = featureFlags['growth-non-pay-enable-invoice'];
  const isUs = companyInfo.region === COUNTRY.US;
  const isFeatureFlagEnabled = featureFlags['unpayable-survey'];

  if (isNonPayEnableInvoiceFlag || !isFeatureFlagEnabled || !isUs) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  const isBusiness = payorSegmentation.isBusiness;
  const FTU = payorSegmentation.isFirstTimeInCP;
  const hasEverPaid = payorSegmentation.previousPaymentDate !== null;

  /* istanbul ignore next */
  useEffect(() => {
    setTimeout(() => {
      const wasShown = localStorage.getItem('unpayable_survey_shown') === 'true';
      if (!wasShown) {
        localStorage.setItem('unpayable_survey_shown', 'true');
        setIsOpen(true);
        SegmentIO.sendEvent('survey:viewed', {
          action: 'viewed',
          object: 'unpayable_survey',
          ui_action: 'loaded',
          ui_object: 'modal',
          ui_object_detail: 'unpayable_survey',
          ui_access_point: 'modal',
        });
      }
    }, 3000);
  }, []);

  function hide() {
    setIsOpen(false);
    SegmentIO.sendEvent('survey:engaged', {
      action: 'viewed',
      object: 'unpayable_survey',
      ui_action: 'loaded',
      ui_object: 'modal',
      ui_object_detail: 'close_unpayable_survey',
      ui_access_point: 'modal',
    });
  }

  return (
    <Fragment>
      {isOpen && (
        <QualtricsModal
          src={`https://customersurveys.intuit.com/jfe/form/SV_4ZoleQ07JNqQdmu?FTU=${FTU}&hasEverPaid=${hasEverPaid}&isBusiness=${isBusiness}`}
          onHide={hide}
        />
      )}
    </Fragment>
  );
};

export default UnpayableSurvey;
