import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import QualtricsModal from 'components/Shared/QualtricsSurveys/QualtricsModal/QualtricsModal';
import SegmentIO from 'reporting/SegmentIO';
import { isFeatureFlagEnabled } from 'store/featureFlags/selectors';
import { insightSelectors } from 'store/insight/selectors';
import {
  isIXPExperimentExistSelector,
  isInIXPExperimentalTreatmentSelector,
} from 'store/ixp/selector';
import { fetchExperiments } from 'store/ixp/slice';
import { saleSelectors } from 'store/sale/selectors';
import { useSelector } from 'store/utils';

export interface Props {}

// https://experimentation-e2e.app.intuit.com/app/ixp-ui/experiments/277789/version/draft?tab=Proposal
// https://experimentation.app.intuit.com/app/ixp-ui/experiments/115788/version/draft?tab=Proposal
const IXP_EXPERIMENT_KEY = 'IXP2_277789';
const IXP_EXPERIMENT_E2E_ID = 277789;
const IXP_EXPERIMENT_PROD_ID = 115788;

// https://featureflags-e2e.app.intuit.com/app/ixp-ui/feature-flags/e2e/maip-npe-survey-enabled/version/latest
// https://featureflags.app.intuit.com/app/ixp-ui/feature-flags/prod/maip-npe-survey-enabled/version/draft
const FF_NAME = 'maip-npe-survey-enabled';

/**
 * Invoice for testing https://connect-e2e.api.intuit.com/t/scs-v1-d4f51592be5b451194ff94debff97d8445da86ed830f497d91f3d032809daf8e406a1770aac4462290d7c3953ba419a1
 */
const MainNpeSurvey: React.FC<Props> = () => {
  const isIxpLoading = !useSelector(isIXPExperimentExistSelector(IXP_EXPERIMENT_KEY));
  const isIxpEnabled = useSelector(isInIXPExperimentalTreatmentSelector(IXP_EXPERIMENT_KEY));
  const isFfEnabled = useSelector(isFeatureFlagEnabled(FF_NAME));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFfEnabled) {
      return; // FF is disabled, IXP should not fetch
    }
    dispatch(
      fetchExperiments({
        experimentIdsPreprod: [IXP_EXPERIMENT_E2E_ID],
        experimentIdsProd: [IXP_EXPERIMENT_PROD_ID],
      })
    );
  }, [isFfEnabled]);

  const [isOpen, setIsOpen] = useState(false);
  const payorSegmentation = useSelector((state) => state.insight.payorSegmentation);
  const invoiceAmount = useSelector((state) => saleSelectors.amountSelector(state.sale));
  const saleID = useSelector((state) => saleSelectors.referenceNumberSelector(state.sale));
  const merchantId = useSelector((state) => insightSelectors.companyIdSelector(state.insight));

  const isBusiness = payorSegmentation.isBusiness;
  const FTU = payorSegmentation.isFirstTimeInCP;
  const hasEverPaid = payorSegmentation.previousPaymentDate !== null;

  useEffect(() => {
    if (!isIxpEnabled || !isFfEnabled) {
      return;
    }
    setTimeout(() => {
      const wasShown = localStorage.getItem('maip_npe_survey_shown') === 'true';
      if (!wasShown) {
        localStorage.setItem('maip_npe_survey_shown', 'true');
        setIsOpen(true);
        SegmentIO.sendEvent('survey:viewed', {
          action: 'viewed',
          object: 'maip_npe_survey',
          ui_action: 'loaded',
          ui_object: 'modal',
          ui_object_detail: 'maip_npe_survey',
          ui_access_point: 'modal',
        });
      }
    }, 3000);
  }, [isIxpEnabled, isFfEnabled]);

  function hide() {
    setIsOpen(false);
    SegmentIO.sendEvent('survey:engaged', {
      action: 'viewed',
      object: 'maip_npe_survey',
      ui_action: 'loaded',
      ui_object: 'modal',
      ui_object_detail: 'close_maip_npe_survey',
      ui_access_point: 'modal',
    });
  }

  if (!isFfEnabled) {
    return <div data-testid="maip-npe-experiment-hidden" />;
  }

  if (isIxpLoading) {
    return <div data-testid="maip-npe-experiment-loading" />;
  }

  if (!isIxpEnabled && !isIxpLoading) {
    return <div data-testid="maip-npe-experiment-hidden" />;
  }

  return (
    <Fragment>
      {isOpen && (
        <QualtricsModal
          src={`https://customersurveys.intuit.com/jfe/form/SV_0riEj4WVtS49uVE?FTU=${FTU}&hasEverPaid=${hasEverPaid}&isBusiness=${isBusiness}&invoiceAmount=${invoiceAmount}&companyID=${merchantId}&saleID=${saleID}`}
          onHide={hide}
          height="700px"
        />
      )}
    </Fragment>
  );
};

export default MainNpeSurvey;
