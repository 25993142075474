import React, { Component, Fragment } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import { CPStore } from 'apps/CPApp';
import PaymentRequestInfo from 'components/PaymentReq/Info/PaymentRequestInfo';
import OneToManyDescription from 'components/PaymentReq/OneToMany/Description/OneToManyDescription';
import Card from 'components/Shared/Card/Card';
import DataRow from 'components/Shared/DataRow/DataRow';
import Hr from 'components/Shared/Hr/Hr';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { fontSize, colors } from 'styles/cp';
import { CompanyInfo } from 'types/CompanyInfo';
import { Insight } from 'types/Insight';
import { Sale } from 'types/Sale';

type PaymentRequestPreviewCardProps = {
  isFullyPaid: boolean;
  txnDate: string;
  description: string;
  currency: string;
  amount: number;
  companyName: string;
  logo: string;
  isOneToMany: boolean;
  estimatedDelivery: string;
};

class PaymentRequestPreviewCard extends Component<PaymentRequestPreviewCardProps, {}> {
  render() {
    const {
      isFullyPaid,
      txnDate,
      description,
      currency,
      amount,
      companyName,
      logo,
      isOneToMany,
      estimatedDelivery,
    } = this.props;
    return (
      <div className="flex">
        <Card padding="16px 20px 20px 20px">
          <div className="details-summary">
            {logo && (
              <div>
                <img className="company-logo" src={logo} />
              </div>
            )}
            <span className="company-name">{companyName}</span>
          </div>
          {isOneToMany ? (
            <OneToManyDescription description={description} estimatedDelivery={estimatedDelivery} />
          ) : (
            <PaymentRequestInfo
              currency={currency}
              amount={amount}
              isFullyPaid={isFullyPaid}
              requestDate={txnDate}
              description={description}
            />
          )}
          {!isFullyPaid && !!amount && amount > 0 && (
            <Fragment>
              <Hr marginTop={10} marginBottom={0} borderColor={colors.offWhite} opacity={1} />
              <DataRow
                idValue="INVOICE_TOTAL"
                defaultMessage="Total"
                bold={true}
                color={colors.black}
                value={<FormattedNumber value={amount} style="currency" currency={currency} />}
              />
            </Fragment>
          )}
        </Card>

        <style jsx>{`
          .details-summary {
            font-size: ${logo ? fontSize.sm : fontSize.ms};

            .company-logo {
              max-height: 60px;
              max-width: 100%;
            }

            .company-name {
              font-family: AvenirNextforINTUIT-Demi;
            }
          }
        `}</style>
      </div>
    );
  }
}

type MapStateToPropsProps = {
  sale: Sale;
  companyInfo: CompanyInfo;
  insight: Insight;
};

function mapStateToProps({ sale, companyInfo, insight }: MapStateToPropsProps) {
  return {
    isOneToMany: saleSelectors.isOneToManyPaymentRequest(sale),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    txnDate: saleSelectors.txnDateSelector(sale),
    description: saleSelectors.descriptionSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    logo: companyInfoSelectors.logoSelector(companyInfo),
    estimatedDelivery: saleSelectors.estimatedDeliverySelector(sale),
  };
}
// @ts-ignore
const with_connectToRedux = connect(mapStateToProps)(PaymentRequestPreviewCard);
const with_CPStore = CPStore(with_connectToRedux);
export default with_CPStore;
