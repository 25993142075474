import React from 'react';

import Separator from 'components/Shared/Separator/Separator';

const { getOneToManyEstimatedDelivery } = require('shared/clientUtils');

export interface Props {
  description: string;
  estimatedDelivery?: string;
}

const OneToManyDescription: React.FC<Props> = ({ description, estimatedDelivery }) => {
  return (
    <React.Fragment>
      <div className="container">
        <h3 className="header">Description of product or service sold</h3>
        <div className="body">{description}</div>
        {estimatedDelivery && (
          <div className="estimated-delivery">
            <Separator height={20} />
            <h3 className="header">Estimated date of service or delivery</h3>
            <div className="body">{getOneToManyEstimatedDelivery(estimatedDelivery)}</div>
          </div>
        )}
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          font-size: 14px;
          padding: 4px 0 16px 0;

          .header {
            font-family: AvenirNextforINTUIT-Medium;
            padding-bottom: 8px;
            font-size: 14px;
            margin: 0;
          }

          .body {
            font-family: AvenirNextforINTUIT-Regular;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default OneToManyDescription;
