import React, { Fragment } from 'react';

import Modal from 'components/Core/Modal/Modal';
import { Close } from 'components/Shared/Icons/Icons';
import { breakpoints, colors } from 'styles/cp';

export interface Props {
  src: string;
  onHide: () => void;
  title?: string;
  height?: string;
}

export const QualtricsModal: React.FC<Props> = ({ src, onHide, title = '', height = '100%' }) => {
  if (!src) {
    return null;
  }

  return (
    <Fragment>
      <Modal>
        <div className="survey-popup-container">
          <button className="close-btn" onClick={onHide} aria-label="Close Survey">
            <Close width={24} height={24} />
          </button>
          <iframe src={src} width="100%" height={height} title={title} />
        </div>
      </Modal>
      <style jsx>{`
        .close-btn {
          position: absolute;
          right: 8px;
          top: 16px;
          cursor: pointer;
          background-color: transparent;
          border: none;
          padding: 12px;
        }

        .survey-popup-container {
          background-color: ${colors.white};
          position: relative;
          width: auto;
          min-width: 700px;
          height: auto;
          min-height: 55vh;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          z-index: 9999;

          @media screen and (max-width: ${breakpoints.md}) {
            width: 90%;
            min-width: 90%;
            height: 80vh;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default QualtricsModal;
